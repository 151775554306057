import { type MediaValue, type PostResponse, type PostView } from '../../../../types'

export const toPostPreview = (post: PostResponse): PostView => ({
  account: {
    name: post.sourceAccount?.platform ?? '',
    url: '',
  },
  description: post.platformData?.caption ?? '',
  media: post.media?.filter((media): media is MediaValue => !!media?.id) ?? [],
  postType: post?.postType ?? 'POST',
  socialName: post.socialAccount?.platform ?? 'INSTAGRAM',
})
