import { type LottieVideoOptions } from '@amplorium/lottie-editor'
import Stack from '@mui/joy/Stack'
import type { ChangeEvent, ReactElement } from 'react'
import { useEffect, useState } from 'react'
import type { Control, FieldPath, FieldValues } from 'react-hook-form'
import { useController } from 'react-hook-form'

import {
  useConvertLottieToVideoMutation,
  useUploadFileMutation,
  useUploadLottieMutation,
  useUploadUrlMutation,
} from '../../store/endpoints/media'
import { useProductQuery } from '../../store/endpoints/shopify'
import { type MediaValue } from '../../types/types'
import { AttachedMedia } from './AttachedMedia'
import { MediaLoading } from './MediaLoading'
import { UploadInput } from './UploadInput'
import { isVideo } from './utils'
import { VideoIconButton } from './VideoIconButton'

interface MediaProps<T extends FieldValues> {
  name: FieldPath<T>
  control: Control<T>
  sourceAccountId?: string
  productId?: string
  error?: boolean
}

const isUploadedPicture = (picture: Partial<MediaValue>): picture is MediaValue =>
  typeof picture.id !== 'undefined'

const Media = <T extends FieldValues>({
  name,
  control,
  sourceAccountId,
  productId,
}: MediaProps<T>): ReactElement => {
  const { field } = useController({ control, name })
  const selectedMedias = (field.value as MediaValue[] | undefined) ?? []
  const selectedMediasIds = selectedMedias.map(({ id }) => id)

  const [unSelectedMedias, setUnSelectedMedias] = useState<Array<Partial<MediaValue>>>([])

  const [uploadFile, { isLoading }] = useUploadFileMutation()
  const [uploadUrl, { isLoading: isLoadingProduct }] = useUploadUrlMutation()
  const [uploadLottie] = useUploadLottieMutation()
  const [convert, { isLoading: isConverting, isSuccess: isConverted }] =
    useConvertLottieToVideoMutation()

  const { data: productData } = useProductQuery(
    { sourceAccountId: sourceAccountId ?? '', productId: productId ?? '' },
    {
      skip: !sourceAccountId || !productId,
    },
  )

  useEffect(() => {
    if (productData?.images != null && Array.isArray(productData.images)) {
      const selectedMediasUrls = selectedMedias.map(({ url }) => url)
      const productMedias = productData.images.filter((url) => !selectedMediasUrls.includes(url))

      setUnSelectedMedias((currentMedias) => {
        const currentMediasUrls = currentMedias.map(({ url }) => url)
        const newMedias = productMedias
          .filter((mediaUrl) => !currentMediasUrls.includes(mediaUrl))
          .map((mediaUrl) => ({ url: mediaUrl }))
        return [...currentMedias, ...newMedias]
      })
    }
  }, [productData, selectedMedias])

  const isSelectedMedia = (id: string): boolean => selectedMediasIds.includes(id)

  const selectMedia = (media: MediaValue): void => {
    field.onChange([...selectedMedias, media])
    setUnSelectedMedias((currentUnSelected) =>
      currentUnSelected.filter(({ id }) => id !== media.id),
    )
  }

  const unSelectMedia = (media: MediaValue): void => {
    field.onChange(selectedMedias.filter(({ id }) => id !== media.id))
    setUnSelectedMedias((currentUnSelected) => [...currentUnSelected, media])
  }

  const removeMedia = (media: Partial<MediaValue>): void => {
    if (isUploadedPicture(media)) {
      field.onChange(selectedMedias.filter(({ id }) => id !== media.id))
    } else if (media.url) {
      setUnSelectedMedias((prev) => prev.filter(({ url }) => url !== media.url))
    }
  }

  const handleMediaClick = async (media: Partial<MediaValue>): Promise<void> => {
    if (isUploadedPicture(media)) {
      isSelectedMedia(media.id) ? unSelectMedia(media) : selectMedia(media)
    } else if (typeof media.url !== 'undefined') {
      setUnSelectedMedias((prev) => prev.filter(({ url }) => url !== media.url))
      const result = await uploadUrl({ url: media.url })
      if ('data' in result && isUploadedPicture(result.data)) {
        selectMedia(result.data)
      }
    }
  }

  const handleInputChange = async (event: ChangeEvent<HTMLInputElement>): Promise<void> => {
    const files = event.target.files
    if (files != null) {
      for (const file of Array.from(files)) {
        const formData = new FormData()
        formData.append('file', file)
        const result = await uploadFile(formData)
        if ('data' in result && isUploadedPicture(result.data)) {
          selectMedia(result.data)
        }
      }
    }
  }

  const handleLottieUpload = async ({
    lottie,
    thumb,
    audio,
  }: LottieVideoOptions): Promise<void> => {
    if (typeof thumb === 'string' && lottie) {
      const response = await uploadLottie({
        lottie: JSON.stringify(lottie),
        thumbnail: thumb,
        audioUrl: audio ?? '',
      })

      if ('data' in response && typeof response.data.id !== 'undefined') {
        const video = await convert({ mediaId: response.data.id })
        if ('data' in video && isUploadedPicture(video.data)) {
          selectMedia(video.data)
        }
      }
    }
  }

  const renderPicture = (picture: Partial<MediaValue>, index?: number): ReactElement | null => {
    const key = picture.id ?? picture.url
    if (typeof key === 'undefined') return null

    return (
      <AttachedMedia
        key={key}
        index={selectedMediasIds.findIndex((id) => id === picture.id)}
        url={picture.url}
        name={key}
        selected={isUploadedPicture(picture) ? isSelectedMedia(picture.id) : false}
        onClick={async () => {
          await handleMediaClick(picture)
        }}
        onRemovePicture={() => {
          removeMedia(picture)
        }}
      />
    )
  }

  const hasConvertedVideo = isConverted && selectedMedias.some(({ url }) => url && isVideo(url))

  console.log('aaaa=', productData?.images)

  return (
    <Stack gap={1.25} direction="row" flexWrap="wrap">
      {selectedMedias.map(renderPicture)}
      {(isLoading || isLoadingProduct) && <MediaLoading />}
      {unSelectedMedias.map(renderPicture)}
      {!isLoading && <UploadInput onChange={handleInputChange} />}
      {isConverting && <MediaLoading />}
      {!isConverting && Array.isArray(productData?.images) && !hasConvertedVideo && (
        <VideoIconButton mediaUrls={productData.images} onVideoUploaded={handleLottieUpload} />
      )}
    </Stack>
  )
}

export default Media
