import { useEffect, useState } from 'react'

import { useAppDispatch } from '../store'
import { usePostsStatisticsQuery } from '../store/endpoints/posts'
import { pushErrorNotification, pushSuccessNotification } from '../store/notifications/actions'
import { type PostResponse } from '../types'

const usePublishingPosts = (): PostResponse[] => {
  const { data: postsStatistics } = usePostsStatisticsQuery()
  const [publishingPosts, setPublishingPosts] = useState<PostResponse[]>([])
  const dispatch = useAppDispatch()

  useEffect(() => {
    console.log(postsStatistics?.PUBLISHING)
    const publishing = postsStatistics?.PUBLISHING ?? 0

    if (publishing > 0) {
      const eventSource = new EventSource('/api/posts/sse/subscribe', { withCredentials: true })

      eventSource.addEventListener('post-update', (event) => {
        const newPublishingPost = JSON.parse(event.data) as Partial<PostResponse>

        if ('status' in newPublishingPost && 'id' in newPublishingPost) {
          setPublishingPosts((prevPosts: PostResponse[]): PostResponse[] => {
            const platform = newPublishingPost.socialAccount?.platform ?? ''
            const postType = newPublishingPost.postType ?? ''
            if (newPublishingPost.status === 'FAILED') {
              const message = `Post to ${platform} (${postType}) was not published`
              dispatch(pushErrorNotification({ message }))
              return prevPosts.filter((post: PostResponse) => post.id !== newPublishingPost.id)
            }
            if (newPublishingPost.status === 'SENT') {
              const message = `Post to ${platform} (${postType}) was successfully published`
              dispatch(pushSuccessNotification({ message }))
            }
            return [
              ...prevPosts.filter((post: PostResponse) => post.id !== newPublishingPost.id),
              newPublishingPost as PostResponse,
            ]
          })
        }
      })

      return () => {
        eventSource.close()
      }
    }
  }, [postsStatistics?.PUBLISHING])

  return publishingPosts
}

export default usePublishingPosts
